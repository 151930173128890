@charset "utf-8";

/******************************
 GLOBAL ELEMENTS
*******************************/
html, body {
  font-family: 'Expletus Sans', Cambria, "Hoefler Text", "Liberation Serif", Times, "Times New Roman", serif;
  width: 100%;
  height: 100%;
  font-weight: 300;
  font-size: 16px;
  line-height: 23px;
  -webkit-font-smoothing: antialiased;
  text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.004);
}

label, input, button, select, textarea {
  font-family: 'Expletus Sans', Cambria, "Hoefler Text", "Liberation Serif", Times, "Times New Roman", serif;
}

.logotype {
  position: absolute;
  z-index: 100;
  top: 80px;
  left: 20px;
  border-radius: 5px;
  background: #fff;
  text-align: center;
  margin: auto;
}

.logotype img {
  margin: 10px;
  width: 300px;
  height: auto;
}

.contact1 {
  position: absolute;
  z-index: 100;
  top: 56px;
  left: 0;
  background: rgba(234, 120, 152, 0.8) url(../img/noisy.png);
  margin: 0 !important;

  h4 {
    padding: 10px;
    margin: 0 !important;
  }
}

.brand {
  font-size: 25px;
  letter-spacing: -1px;
  color: #aa78a0;

  a {
    padding: 10px;
    font-weight: 700;
    color: #aa78a0;
  }
}

.brand1 {
  text-align: center;
  padding-top: 10px;
}

.bottom-caption {
  padding-top: 10px;
  padding-bottom: 4px;

  span,
  a {
    color: #aa78a0;
    font-size: small;
    font-weight: 600;
  }

  i {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    font-size: 20px;
    letter-spacing: 10px;
  }
}

.dropcap {
  font-size: 76px;
  font-weight: 700;
  margin-right: 4px;
  color: #fff;
  text-align: center;
  line-height: 56px;
  text-transform: uppercase;
  float: left;
  padding-top: 16px;
  padding-right: 2px;
  padding-bottom: 2px;
  padding-left: 2px;
}

.navbar {
  background: #fff;
  box-shadow: 0 -20px 20px -20px rgba(0, 0, 0, 0.35);

  .nav {
    margin: 0 auto;
    float: none;

    li {
      color: #fff;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 2px;
      text-transform: uppercase;
      margin-top: 4px;

      &:hover {
        color: #a85e8f;
        cursor: pointer;
      }
    }
  }
}

.nav ul {
  text-align: center;
  margin-top: 5px;
  margin-bottom: -1px;

  li {
    display: inline-block;
    padding: 5px 8px 7px 12px;

    i {
      color: #795e63;
      text-decoration: none;

      &:hover {
        color: #a85e8f;
        cursor: pointer;
      }
    }
  }
}

.nav-bg {
  background-color: #000;
}

.container {
  position: relative;
}

.scroll-slide {
  background-attachment: fixed;
}

.slidename {
  font-family: "Expletus Sans", cursive;
  bottom: 0;
  left: 0;
  font-size: 55px;
  line-height: 130px;
  text-align: center;
  letter-spacing: -3px;
  font-weight: 700;
  color: #fff;
  text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.20);
}

.scissorz {
  border-top: 2px dashed #fff;
  margin-bottom: 10px;
  margin-top: 20px;

  &:after {
    font-size: 23px;
    color: #fff;
    position: relative;
    top: -13px;
    left: 20px;
    font-family: 'FontAwesome';
    content: '\f0c4';
  }
}

.scissorz-block {
  border: 2px dashed #795e63;
  margin: 10px 0 20px;
  padding: 10px;
  padding-top: 0;
  border-radius: 5px;

  &:before {
    font-size: 23px;
    color: #795e63;
    position: relative;
    top: -13px;
    left: 20px;
    font-family: 'FontAwesome';
    content: '\f0c4';
  }
}

.alert {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
}

.more-margin-bottom {
  padding-bottom: 100px;
}

.pad-both {
  padding-top: 40px;
  padding-bottom: 60px;
}

.pad-top {
  padding-top: 40px;
}

.pad-bottom {
  padding-bottom: 40px;
}

section {
  height: 40px;
}

/******************************
 SLIDE 1
*******************************/
#slide1 {
  background-color: #bab5b1;
  width: 100%;
  height: 100%;
  position: relative;
}

.slide1-separator {
  background: url(../img/slide-separator-contact.png);
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 55px;
  background-repeat: repeat-x;
  background-position: bottom;
  z-index: 100;
}

.carousel {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  line-height: 1;
}

.carousel-inner {
  width: 100%;
  height: 100%;
  margin: 0;
  position: absolute;
}

.carousel .item {
  -webkit-transition: opacity 1s;
  -moz-transition: opacity 1s;
  -ms-transition: opacity 1s;
  -o-transition: opacity 1s;
  transition: opacity 1s;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 60px !important;
  padding-bottom: 40px !important;
  background-position: center;

  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -.25em;
  }

  h4 {
    -moz-animation: moveUp 1s ease-in-out both;
    -o-animation: moveUp 1s ease-in-out both;
    -ms-animation: moveUp 1s ease-in-out both;
    -webkit-animation: moveUp 1s ease-in-out both;
    animation: moveUp 1s ease-in-out both;
  }

  p {
    -moz-animation: fadeIn .5s linear .5s both;
    -o-animation: fadeIn .5s linear .5s both;
    -ms-animation: fadeIn .5s linear .5s both;
    -webkit-animation: fadeIn .5s linear .5s both;
    animation: fadeIn .5s linear .5s both;
  }
}

.carousel .active.left,
.carousel .active.right {
  left: 0;
  opacity: 0;
  z-index: 2;
}

.carousel .next,
.carousel .prev {
  left: 0;
  opacity: 1;
  z-index: 1;
}

.carousel-caption {
  background: url(../img/noisy.png) rgba(255, 255, 255, 0.6);
  font-weight: 300;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  width: 30%;
  height: 40%;
  padding: 10px 40px 0;
  margin: auto;
  text-align: center;

  span,
  a {
    color: #aa78a0;
    font-family: "Josefin Sans", cursive;
    font-weight: 700;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    font-size: 2.8em;
    position: relative;
    top: 33%;
  }
}

.carousel-control {
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.85);
}

@-moz-keyframes moveUp {
  0% {
    -moz-transform: translateY(40px);
  }

  100% {
    -moz-transform: translateY(0px);
  }
}

@-o-keyframes moveUp {
  0% {
    -o-transform: translateY(40px);
  }

  100% {
    -o-transform: translateY(0px);
  }
}

@-ms-keyframes moveUp {
  0% {
    -ms-transform: translateY(40px);
  }

  100% {
    -ms-transform: translateY(0px);
  }
}

@keyframes moveUp {
  0% {
    transform: translateY(40px);
  }

  100% {
    transform: translateY(0px);
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/******************************
 SLIDE 2
*******************************/
#slide2 {
  background: #f7f1e0 url(../img/noisy.png);
  width: 100%;
  height: auto;
  position: relative;
}

.service-blocks {
  padding: 6px 8px 10px;
  background: -webkit-linear-gradient(top, #ede1c9, #fef8e2 20%, #f2ebde 60%);
  background: -moz-linear-gradient(top, #ede1c9, #fef8e2 20%, #f2ebde 60%);
  background: -o-linear-gradient(top, #ede1c9, #fef8e2 20%, #f2ebde 60%);
  background: -ms-linear-gradient(top, #ede1c9, #fef8e2 20%, #f2ebde 60%);
  background: linear-gradient(top, #ede1c9, #fef8e2 20%, #f2ebde 60%);
  -webkit-box-shadow: 4px 4px 8px -4px rgba(0, 0, 0, .75);
  -moz-box-shadow: 4px 4px 8px -4px rgba(0, 0, 0, .75);
  box-shadow: 4px 4px 8px -4px rgba(0, 0, 0, .75);
  text-align: center;
  color: #433a3c;
  padding: 20px;
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 10px;
  background: #fff;

  &:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
    transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
  }

  img {
    margin-bottom: 20px;
  }

  h3 {
    font-family: "Josefin Sans", cursive;
    font-size: 36px;
    color: #aa78a0;
  }

  p {
    margin-top: 10px;
  }
}

.service-blocks-hole {
  border-radius: 100px;
  margin: 0 auto;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.35);
  background: #f7f1e0 url(../img/noisy.png);
}

.service-icon {
  position: relative;
  left: -30px;
  z-index: 10;
  margin-bottom: 10px;

  i {
    font-size: 55px;
    color: #fff;
    position: relative;
    top: -35px;
    left: 93px;
    text-shadow: 0 0 8px rgba(0, 0, 0, 0.35);
  }

  &:after {
    font-size: 150px;
    margin: 0 auto;
    color: #a85e8f;
    line-height: 120px;
    font-family: 'FontAwesome';
    content: '\f111';
    text-shadow: 0 2px 5px rgba(190, 180, 140, 0.4);
  }
}

/******************************
SLIDE3 PARALLAX SLIDE
*******************************/
#slide3 {
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 30%;
  max-height: auto;
  position: relative;
}

.slide3-separator {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 55px;
  background-repeat: repeat-x;
  background-position: bottom;
}

.fancy-test {
  box-shadow: 0 0 16px rgba(68, 31, 46, 0.65);
  background: rgba(0, 0, 0, 0.45) url(../img/noisy.png);
  margin: -10px 0 30px;
  padding: 10px;
  border-radius: 5px;

  &:before {
    font-size: 23px;
    color: #fff;
    position: relative;
    top: -13px;
    left: 50%;
  }

  &:after {
    font-size: 23px;
    color: #fff;
    position: relative;
    top: 23px;
    left: 50%;
  }

  p {
    font-size: 30px;
    text-align: center;
    text-shadow: 0 0 8px rgba(0, 0, 0, 0.95);
    line-height: 39px;
  }

  cite {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
  }
}

/******************************
 SLIDE 5
*******************************/
#slide5 {
  background: #a59e94 url(../img/noisy.png);
  width: 100%;
  height: auto;
  max-height: auto;
  position: relative;
}

.slide5-separator {
  background: url(../img/slide-separator-contact.png);
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 55px;
  background-repeat: repeat-x;
  background-position: bottom;
  z-index: 100;
}

.photographer {
  background: rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.about-text {
  margin-top: 4px;
}

.skill {
  color: #f1f1f1;
  margin-top: 12px;
  font-size: 12px;
  font-style: italic;
}

.fancy-heading-big {
  text-align: center;
  border-bottom: 1px solid #fff;
  margin-bottom: 35px;
}

.fancy-heading-big h3 {
  display: inline-block;
  line-height: 11px;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 3px;
  position: relative;
  top: 25px;
  padding: 8px 10px;
  background: #a59e94 url(../img/noisy.png);
  color: #fff;
}

.fun-facts {
  text-align: center;
  margin-bottom: 30px;
}

.progress {
  background: #fff url(../img/noisy.png);
}

.fun-fact {
  background: #aa78a0 url(../img/noisy.png);
  border: 10px solid #fff;
  border-radius: 50%;
  display: inline-block;
  padding: 45px 20px 55px;
  text-align: center;
  margin-bottom: 20px;
  height: 60px;
  width: 120px;
  margin-right: 10px;
}

.fun-fact-digit {
  font-size: 40px;
  font-weight: 700;
  line-height: 35px;
  padding-bottom: 10px;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
}

.fun-fact-text {
  padding-top: 5px;
  font-size: 12px;
  line-height: 13px;
}

.fun-fact:hover {
  transform: scale(1.2);
  transition: all .2s ease-in-out;
  -webkit-transform: scale(1.2);
  -webkit-transition: all .2s ease-in-out;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
}

.fun-fact:hover .fun-fact-digit {
  transform: scale(1.5);
  transition: all .2s ease-in-out;
  -webkit-transform: scale(1.5);
  -webkit-transition: all .2s ease-in-out;
}

.fun-fact:hover .fun-fact-text {
  transform: scale(1);
  transition: all .2s ease-in-out;
  -webkit-transform: scale(1);
  -webkit-transition: all .2s ease-in-out;
}

/******************************
SLIDE6
*******************************/
#slide6 {
  background: #eee url(../img/noisy.png);
  color: #555;
  height: auto;
  max-height: auto;
  position: relative;
}

.contact-us-form {
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
  padding: 15px;
  margin-bottom: 40px;
}

.contact-us-form a {
  color: #555;
}

.contact-us-form-inner {
  background: #fff;
  border-radius: 3px;
  padding-bottom: 0;
}

.contact-us-form-inner-stamp {
  background: url(../img/logo-Mademoiselle1.png) no-repeat;
  background-position: bottom right;
  padding: 15px;
  padding-bottom: 0;
}

.contact-us-form .media {
  margin-top: 0;
  font-size: 13px;
}

.get-us {
  background: rgba(215, 214, 207, 0.8);
  padding: 5px 20px;
  border-radius: 3px;
  margin-bottom: 15px;
}

.get-us:before {
  font-size: 33px;
  background: #555;
  padding: 10px;
  color: #fff;
  border-radius: 50%;
  position: relative;
  top: 20px;
  left: 42%;
}

.address-block, .media-body {
  text-align: center;
  border-top: 1px dashed #bcbbaf;
  border-bottom: 1px dashed #bcbbaf;
  text-transform: uppercase;
  margin-bottom: 15px;
  margin-top: 15px;
  padding-top: 25px;
  font-size: 14px;
}

.address-block a {
  text-decoration: underline;
}

.media i {
  margin-top: 10px;
}

.contact-us-form h1 {
  line-height: 25px;
  margin-bottom: 20px;
}

.contact-us-form h4 {
  margin: 0;
  margin-bottom: 5px;
  line-height: 20px;
}

.contact-us-form h3 {
  font-size: 25px;
  line-height: 40px;
  margin: 0;
}

.contact-us-form .required {
  text-transform: uppercase;
  color: #999;
  font-size: 13px;
}


/* footer
================================================== */
.footer {
  background: #eee url(../img/noisy.png);
  font-size: 14px;
  line-height: 28px;
  color: #333;
  padding-top: 30px;
  padding-right: 30px;
  padding-bottom: 40px;
  padding-left: 30px;
}

.footer p {
  margin: 0;
  color: #555;
  background: none repeat scroll 0 0 #fff;
  display: inline;
  margin: 0;
  padding: 3px 7px;
}

.footer h4 {
  font-family: 'Josefin Sans', cursive;
  font-size: 48px;
  line-height: 45px;
  margin-bottom: 20px;
  color: #a85e8f;
  font-weight: 300;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
}

.footer .fancy-heading {
  text-align: center;
  border-bottom: 1px solid #a85e8f;
  margin-bottom: 25px;
}

.lead {
  font-size: 19px;
}

.footer .fancy-heading h3 {
  font-family: 'Expletus Sans', Cambria, "Hoefler Text", "Liberation Serif", Times, "Times New Roman", serif; /*'Lato',serif,"Helvetica Neue",Helvetica,Arial;*/
  display: inline-block;
  line-height: 11px;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 3px;
  text-transform: uppercase;
  position: relative;
  top: 25px;
  padding: 8px 10px;
  background-color: #a85e8f;
  color: #292325;
}

.footer-links {
  margin: 10px 0;
}

.footer-links li {
  display: inline;
  padding: 0 2px;
}

.footer-links li:first-child {
  padding-left: 0;
}

.footer a {
  color: #444;
  text-decoration: underline;
}

.footer a:hover {
  color: #222;
}

.footer-thumbs ul {
  list-style: none;
  display: inline-block;
  margin: 0;
}

.footer-thumbs li {
  margin-left: 6px;
  display: block;
  float: left;
  margin-bottom: 6px;
}

.social-buttons {
  position: absolute;
  width: 100%;
  z-index: 100;
  top: 60vh;
}

.social {
  list-style: none;
  display: block;
  font-size: 28px;
  margin: auto;
  text-align: center;
  color: #aa78a0;
}

.social li {
  display: inline;
  float: left;
  -webkit-transition-property: opacity;
  -webkit-transition-duration: 500ms;
  -moz-transition-property: opacity;
  -moz-transition-duration: 500ms;
}

.social li a {
  display: block;
  color: #aa78a0;
  text-align: center;
  text-decoration: none;
  text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
  padding: 2px 0;
}

.social:hover li {
  opacity: .2;
}

.social li:hover {
  opacity: 1;
  transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
  transform: scale(1.6, 1.6);
  -webkit-transform: scale(1.6, 1.6);
}

.coupon {
  position: relative;
  display: inline-block;
  padding: 8px 25px 10px;
  margin: 5px 2px;
  min-height: 60px;
  background: #d3cbae url(../img/noisy.png);
  font-size: 25px;
}

.coupon i {
  float: left;
  color: #fff;
  background: rgba(190, 104, 113, 0.99) url(../img/noisy.png);
  padding: 10px 13px;
  font-size: 38px;
  border-radius: 50%;
  box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.2);
}

.coupon-text {
  margin-left: 70px;
  font-size: 13px;
  line-height: 18px;
}

.coupon:before {
  content: "";
  position: absolute;
  top: 40%;
  left: -8px;
  width: 20px;
  height: 20px;
  background: #f4f4f4 url(../img/noisy.png);
  border-radius: 50%;
}

.coupon:after {
  content: "";
  position: absolute;
  top: 40%;
  right: -8px;
  width: 20px;
  height: 20px;
  background: #f4f4f4 url(../img/noisy.png);
  border-radius: 50%;
  box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.3);
  overflow: hidden;
}


@media (max-width: 998px) {
  .nav-bg {
    background-color: #fff;
  }

  .navbar .nav li {
    color: #000;
  }
}

@media (max-width: 480px) {
  .contact-us-form-inner-stamp {
    background: none;
  }

  .desktop {
    display: none;
  }

  .navbar-decor {
    margin-left: -5px;
  }

  .navbar .nav li {
    font-size: 14px;
  }

  .nav ul {
    margin-left: 0;

    li {
      display: inline-block;
      padding: 5px 8px 7px 12px;
    }
  }

  .logotype {
    position: absolute;
    z-index: 100;
    top: 10px;
    left: 10px;
    width: 100px;
    height: 100px;
  }

  .carousel-caption {
    width: 180px;
    height: 165px;
    padding: 40px;
    padding-top: 55px;

    h4 {
      font-size: 45px;
      letter-spacing: -2px;
      line-height: 45px;
    }

    p {
      font-size: 11px;
      line-height: 12px;
    }
  }

  .carousel-control {
    font-size: 30px;
  }

  .contact-us-form .media-object {
    float: left;
  }

  .carousel .item {
    width: auto;
    height: 100%;
  }

  .fancy-heading {
    border-bottom: 1px solid #795e63;
    margin-bottom: 35px;
  }

  .fancy-heading h3 {
    line-height: 11px;
    font-size: 26px;
    letter-spacing: -1px;
    top: 25px;
    padding: 8px 10px;
  }

  .logotype h1 {
    font-size: 40px;
    line-height: 20px;
    margin-top: 25px;
    letter-spacing: -2px;
  }

  .logotype h2 {
    font-size: 8px;
    color: #fff;
    padding: 5px 15px;
    letter-spacing: 3px;
    line-height: 9px;
  }

  .fancy-test p {
    font-size: 20px;
    text-align: center;
    text-shadow: 0 0 6px rgba(0, 0, 0, 0.85);
    line-height: 25px;
  }

  .fancy-test cite {
    text-align: center;
    font-size: 14px;
    margin-bottom: 16px;
  }

  .fun-facts {
    text-align: center;
  }

  .fun-fact {
    padding: 25px 5px 35px;
    margin-bottom: 30px;
    height: 60px;
    width: 110px;
    margin-right: 15px;
  }

  .fun-fact-text {
    padding-top: 5px;
    font-size: 11px;
    line-height: 13px;
  }

  .contact-us-form h1 {
    line-height: 35px;
    text-align: center;
    margin-bottom: 20px;
  }

  .required {
    text-align: center;
  }

  .contact-us-form h4 {
    line-height: 20px;
  }
}

.desktop i {
  font-size: x-large;
}

#block-answer {
  margin-bottom: 12px;
}

.color-danger {
  color: darkred;
}

.color-success {
  color: green;
}
