// Bootstrap
@import "./vendor/bootstrap.css";
@import "./vendor/bootstrap-responsive.css";
//@import "./vendor/font-awesome.min.css";

// Bootstrap Icons
$bootstrap-icons-font-dir: "../fonts" !default;
@import "~bootstrap-icons/font/bootstrap-icons";

@import "mixins";
//@import "variables";
@import "layout";
